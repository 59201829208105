require('./bootstrap');

function initChart(data) {
    /* Chart code */
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new('campaign-report-chart-div');
    let chart, legend;

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: 'panX',
            wheelY: 'zoomX',
            maxTooltipDistance: 0,
            layout: root.horizontalLayout,
        })
    );

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
            maxDeviation: 0.2,
            baseInterval: {
                timeUnit: 'hour',
                count: 1,
            },
            renderer: am5xy.AxisRendererX.new(root, {}),
            tooltip: am5.Tooltip.new(root, {}),
        })
    );

    let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {}),
        })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    for (var i = 0; i < data.length; i++) {
        let series = chart.series.push(
            am5xy.LineSeries.new(root, {
                name: data[i].name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: 'value',
                valueXField: 'date',
                legendValueText: '{valueY}',
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: 'horizontal',
                    labelText: '{valueY}',
                }),
            })
        );

        series.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['value'],
            dateFields: ['date'],
            dateFormat: 'yyyy-MM-dd H:m:s',
        });

        series.data.setAll(data[i].data);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();
    }

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
            behavior: 'none',
        })
    );
    cursor.lineY.set('visible', true);

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    chart.set(
        'scrollbarX',
        am5.Scrollbar.new(root, {
            orientation: 'horizontal',
        })
    );

    chart.set(
        'scrollbarY',
        am5.Scrollbar.new(root, {
            orientation: 'vertical',
        })
    );

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    legend = chart.children.push(
        am5.Legend.new(root, {
            width: 350,
            paddingLeft: 15,
            height: am5.percent(100),
            verticalScrollbar: am5.Scrollbar.new(root, {
                orientation: 'vertical',
            }),
        })
    );

    // When legend item container is hovered, dim all the series except the hovered one
    legend.itemContainers.template.events.on('pointerover', function (e) {
        let itemContainer = e.target;

        // As series list is data of a legend, dataContext is series
        let series = itemContainer.dataItem.dataContext;

        chart.series.each(function (chartSeries) {
            if (chartSeries != series) {
                chartSeries.strokes.template.setAll({
                    strokeOpacity: 0.15,
                    stroke: am5.color(0x000000),
                });
            } else {
                chartSeries.strokes.template.setAll({
                    strokeWidth: 3,
                });
            }
        });
    });

    // When legend item container is unhovered, make all series as they are
    legend.itemContainers.template.events.on('pointerout', function (e) {
        let itemContainer = e.target;
        let series = itemContainer.dataItem.dataContext;

        chart.series.each(function (chartSeries) {
            chartSeries.strokes.template.setAll({
                strokeOpacity: 1,
                strokeWidth: 1,
                stroke: chartSeries.get('fill'),
            });
        });
    });

    legend.itemContainers.template.set('width', am5.percent(100));
    legend.labels.template.setAll({
        oversizedBehavior: 'truncate',
        maxWidth: 200,
        textAlign: 'left',
    });

    // It's is important to set legend data after all the events are set on template, otherwise events won't be copied
    legend.data.setAll(chart.series.values);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    // Move the legend to the bottom on smaller screens.
    $(window).resize(function () {
        if (!chart || !legend) {
            return;
        }

        // Move the legend below the chart.
        if ($('#campaign-report-chart-div').width() < 800) {
            chart.set('layout', root.verticalLayout);
            legend.set('width', am5.percent(100));
            legend.labels.template.setAll({
                maxWidth: am5.percent(100),
            });
        } else {
            // Restore the original legend position.
            chart.set('layout', root.horizontalLayout);
            legend.set('width', 350);
            legend.labels.template.setAll({
                maxWidth: 200,
            });
        }
    });
}

window.showToastMessage = function (title, when, body) {
    $('#toast-box-title').text(title);
    $('#toast-box-when').text(when);
    $('#toast-box-body').text(body);
    $('#toast-box').toast('show');
};
